import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../components/Layout'
import styled from 'styled-components';
import { Link } from "gatsby"

const MainWraper =  styled.div`

  display: flex; /* or inline-flex */

  /* Center horizontally*/
  margin: 0 auto;  

  //border: 1px solid blue;
  width: 700px;
  @media screen and (max-width: 960px) {
    display: block;
    width: 400px;
  }      

`

const Row = styled.div`
  padding: 5px;
  //border: 1px solid red;
  width: 700px;
  @media screen and (max-width: 960px) {
    width: 400px;
  }      
`

const ImageLink = styled.div`


    &:hover {      
      color: #a5a5a5;  
      background-color: gray;      
    }  /* mouse over link */

`

const Information = () => {

  return (
    <>
      <div><p>Click on the picture to see more information</p></div>
      <MainWraper>            
          <Row>
            <ImageLink>
                <Link to="/wedding/bsas">
                  <StaticImage src="../../images/wedding/bsas.jpg" width={1196}  height={1682}   />                                  
                </Link>    
              </ImageLink>                            
            </Row>              
        </MainWraper>
      </>
    )
}



const Page = () => {

  return (
    <Layout section="wedding" pageTitle="Wedding">
      <Information />
    </Layout>
  )
}

export default Page